import * as React from "react";
import "./App.css";
import { vfiles } from './virtualfiles.jsx';
import { TerminalContextProvider } from "react-terminal";
import { ReactTerminal } from "react-terminal";

const App = () => {
//  const [theme] = React.useState("matrix");
  const [controlBar] = React.useState(false);
  const [controlButtons] = React.useState(true);
  const [prompt] = React.useState("guest@jacobhitchins.com:~$");

  const commands = {
    help: (
      <span>
        <strong>- - - - - - - - - - - - - - -</strong><br />
        <strong>Below is a list of the commands you can use;</strong><br />
        <strong></strong><br />
        <strong>clear</strong> - Clears the terminal. <br />
        <strong>ls</strong> - List files available to view.<br />
        <strong>cat</strong> - Print contents of the specified file to screen.<br />
        <strong>whoami</strong> - Prints information about the current user.<br />
        <strong>date</strong> - Prints the current system date and time.<br />
        <strong>sudo</strong> - Execute a command as the superuser.<br />
        <strong>- - - - - - - - - - - - - - -</strong>
      </span>
    ),

    ls: () => {
      return <span>
        <text>about.txt</text><br />
        <text>contact.txt</text><br />
        <text>pgpkey.txt</text><br />
        <text>consulting.txt</text>
      </span>
    },

    cat: (filename) => {
      const validfiles = [
        "about.txt",
        "contact.txt",
        "pgpkey.txt",
        "consulting.txt",
      ];
      if (!validfiles.includes(filename)) {
        return `Filename ${filename} not valid. Try one of; ${validfiles.join(", ")}`;
      }
      if (filename === 'consulting.txt') {
        window.open("https://sigil.com.au");
      }
      var parsedfilename = filename.replace (/\./g,'_')
      return (<text>{vfiles[parsedfilename]}</text>)
    },

    sudo: () => {
      return (<text>Username is not in the sudoers file. This incident will be reported.</text>)
    },

    whoami: () => {
      const wai_username = "Username: guest"
      const wai_host = "Host: jacobhitchins.com"
      const wai_platform = "Platform: " + navigator.platform
      const wai_corecount = "Cores: " + navigator.hardwareConcurrency
      const wai_language = "System Language: " + navigator.language
      return (<text>{wai_username}<br />{wai_host}<br />{wai_platform}<br />{wai_corecount}<br />{wai_language}</text>)
    },

    date: () => {
      return Date()
    },
  };

  const welcomeMessage = (
    <>
    <span>
      <strong style={{ color: 'white' }}>Welcome to jacobhitchins.com! Execute the command 'help' to view options.</strong> <br />
    </span>
    </>
  );

  return (
    <div className="App">
      <TerminalContextProvider>
        <ReactTerminal
          prompt={prompt}
          theme="jtheme"
          showControlBar={controlBar}
          showControlButtons={controlButtons}
          welcomeMessage={welcomeMessage}
          commands={commands}
          themes={{
            jtheme: {
              themeBGColor: "#000000",
              themeToolbarColor: "#000000",
              themeColor: "#39ff14",
              themePromptColor: "#39ff14"
            } 
          }}
          defaultHandler={(command, commandArguments) => {
            return (<text style={{ color: 'red'}}>'{command}' is not a valid command! Execute <b><i>help</i></b> to view options.</text>);
          }}
        />
      </TerminalContextProvider>
    </div>
  );
}

export default App;