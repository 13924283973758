export var vfiles = {
    about_txt: (<text>Hi, my name's Jake - I'm a cybersecurity engineer & ICT consultant currently based in Melbourne (Australia) with about 7 years of business experience.<br /><br />I'm currently in a full time role as an application security engineer in the telecoms industry, with a particular interest in SecDevOps & security automation.<br /><br />Previously I worked as a penetration tester specialising in web application and internal network security testing and in the past I've also spent time as a linux & virtualisation (mainly vSphere/ESXi) administrator and a network technician.<br /><br />My primary interests include information security, virtualisation/containerisation technologies, decentralised systems, cryptography, online privacy & digital rights.<br /><br />I also enjoy music, multimedia art, reading & cycling.</text>),
    contact_txt: (<text>If you're looking to get in contact with me you can do so via contact@jacobhitchins.com.<br /><br />Feel free to use PGP for secure communication/authentication, my fingerprint is FBD2 3D20 79F7 AE6A.<br /><br />You can locate my public key at jacobhitchins.com/jhpubkey.txt or by typing 'cat pgpkey.txt' into this terminal.</text>),
    pgpkey_txt: (<text>
    -----BEGIN PGP PUBLIC KEY BLOCK-----<br />
    <br />
    mQINBF+7AC8BEADpEVqeBlF+jxSkzC4aVeA8Z0yTzJBL+/ko+owgn/Tx24qnSdSc<br />
    pLqrypx02xagImHjeNGL3O9P9djjZ8W+sjaWp6PAMqFb55fhit8ltUV0ZI/eDeI2<br />
    rUhK5HFKlaDy1BSmsHhLkzt66PjIP3J/hhdwmhMEkQKN3/tqk5N/1uILeRX+elTt<br />
    pSm9pgqxULjqewbtlr8U6Tawu0OLDN3mOtwJ89v7GcHf2qgtGbZ4OcYU3Xva2HjS<br />
    dCUM3SYvjOaTJtApoclrhbJvoBibCbS/DVJ7K0E8/ob+Cz/1pzbKMPEc9+cvB2Yw<br />
    myfGcDDMD8W1Lys5aIhe3AHAj9xiUyURDaIr/qwrVpA2SQv5Dfl4QyowTSitO35P<br />
    oUGPgMJ2ZnxhR2apmyHHC1Jr/xkLnnBglvUT0AFOqG7TqM11QeV7IcNwKSdtOPlF<br />
    +tXeiKG8gE6tg08U4Xb/wysA8oEyiGplDgF7VCEJpt74knjOo7jjOPd7fQ90xkXw<br />
    +0L+fVazZa6FRseFXN1CruRu7drY9qw2n1eOGf/dseqrwYyU9hCH0n2briScIy+c<br />
    amcmgWhX1eaZqFsiUOz60mhDQnhpAJetgx6prR/eTVsIgYyPpoLBz/FV2RsXoJYU<br />
    rqt1bq8F0vhCD1/LtbghiqBbrsEPO8NXAIvrRKz41pQwbJogL8ww/PdfdQARAQAB<br />
    tCdKYWNvYiBIaXRjaGlucyA8bWFpbEBqYWNvYmhpdGNoaW5zLmNvbT6JAkwEEwEK<br />
    ADYWIQQRsdfMdgTVWuJ+HIL70j0gefeuagUCX7sALwIbAQQLCQgHBBUKCQgFFgID<br />
    AQACHgECF4AACgkQ+9I9IHn3rmo1VA//XEKWyddxtPtHuuV3TTw7ijKsx5bYuWa1<br />
    UF2HIGDCbnpsqAen3Te1JkOjZJaQSIa+sGinujmjk6Y0UsAGjqAM2+qZ4S3+Nj7a<br />
    ZirKJTgaGZ9gjjTVI5OfuhZgUpbnyJU/jcYzTnc7d35nEACAfrufCmVPpo3pOf3q<br />
    VL0OuvykbqCwrZ2XzGjZqlx5z6svBuIemtvTMlPykkHVAHDlm8Q1hc8ZGUbh6Q+l<br />
    luwSH40kEXbkhGu8AKrYxg15f50jgXn1aVJjMOrfZSVU6D8AWSzpHFsvXmH5Vk4G<br />
    tXfPZ+EQz8KJ4EwK3piLLkXiQ0la+yOirEsZkynYbEEko527N4gODeltQp3tRIXR<br />
    /zM3ccFB9d4uKRUlnZVwWVyGpfSqrfAdbDRrOtLD4NRtK+G2Q3hqMGqQuM1b/YLw<br />
    6mF7hAHFlA55eCLLTsVrXH+U25PJvvpPNICnO0bIMg4lI5P/o4LBuaE+yUHybucm<br />
    AdN4BKEOskz9LSWcHVX+84rwXlI2ogOuly5uHU2taj0mMT0YAPi2uqx/nQ0fT9Iu<br />
    qBBel9Fxdj3igwqoRgBL3rwD2PEQRwhCkphS7/b1feuqo/Wyr83Uvjm5HdCeVmN9<br />
    3YsypHKyV9yk+cL8ar7e2+Wdyf/h0Xae9s6bo92b3D0okeitiyilYWr7kHEgEox8<br />
    hLmc1UGXf1S5Ag0EX7sA5QEQAMUkwRuqYztG7vfIEVw7AE8m4o//If9BUvdwfc8a<br />
    z0idbb4LTl80D+4v80eDbQ+q4m8YEXBVfEv38P2LewWleT8vQpcUUb/hYJXYzXkX<br />
    JGWWb2S7RKBNPmR9f7ecFnXaK++p1hGABNV66FCMwbataf51f3/TDLV+I6BP8FqX<br />
    9Ngb0ZGQjUJvfq9Tul+vYuPihKYXN9O0wmTok17ZKsrOIjqjpHeA9xh+3W9+dmNS<br />
    8gxDUGAW3CsvHE6KQHPP9E0ntWV+y3V0x9Z7iqxwe6XM0l+/T3G4OPQb1w9RFR4m<br />
    XGQ79wRksERSvZyF34xvpjoT+Fae6Qaf7Oqe8rD0Li4sElJLy6tPjAWyoKO6jGd6<br />
    wfO8FdHLLTLvG7rJGaATbzEqdO5oCg4JNYolw46j5jq9mTXPUYH8BLgAzRYm4cJR<br />
    cbOMY17IRcBUADEXHLuheSJ0uOrvgl2B8Zzv7gXnTIEIuPQPQROaFLeT30D6gy0f<br />
    i1B5/GgVqvxLG+ssYBIh1ireMnzuN+StYQPZECaWUwomyaJggtwds8OwmkpOmBaC<br />
    XsIELKTQ9D/2g8+Pokgd6jw5+KEjNJSP3h3AivqUz2GAFdhVSE0VtPfKyN11pfmH<br />
    y+oKn4lkvVk3yta4VwFBSgrPRQU7j1izcF5IehETZB/njdKbfDy81CyiQim8brF1<br />
    QVU7ABEBAAGJBHIEGAEKACYWIQQRsdfMdgTVWuJ+HIL70j0gefeuagUCX7sA5QIb<br />
    AgUJAeEzgAJACRD70j0gefeuasF0IAQZAQoAHRYhBLnxeEDVitrsHRezN7Y0JtqO<br />
    qhWoBQJfuwDlAAoJELY0JtqOqhWoCFMP/31AawGG+S++jAAlMpMsHoztab2fVL0g<br />
    9YezzuSfTyPyPHKWx/RHWixq7k3eaeZ9OiBWC06WrG2eFaM50u2MGoQvyBpvR4N5<br />
    X7hwT4abBxD26VpSx8Icjpblw9YBA4povHINmT7BXsnRwu3uCLZ0OT1lbVMdU5y2<br />
    tateuH3Qel9IkIWqXyQnuieNiqCXSDuEoRDUtBh6oYVK1raR0fxudgBNGF1lALjc<br />
    2xsS9zQ2w+ML1c7OjIwd59DbGrs6vKKgtjrWIQXiWkKJWQb27mOxxFc06vkckXxw<br />
    U6etbQ/LyiuInjH4hrayX2sntyO21sR4HAv+iq4FktutDLT0EocqT1I1nv/SqqPB<br />
    EglmfN8MdBIK558Cf+bhKJHKU81tOJNEPkTy1udA6dnp+yfWAGFn41XotIHQWl93<br />
    PBC1NCKJFxHmsrf+ffOQRh0ik/cnl6n0cXq8M2z589nZrPp74ZDziUzjIHypARux<br />
    p5XPWiFBfuJIKiq53/9lumwvZLarUVqa8VxxBDiPNkZRpd/yUleSR5nOusxhDVkH<br />
    rSdFfyJyrgwqbv9rTe9fDq1rT9uHBLslfC+cW47H5UWGO4qb6s3OHrXbx0xp2rr5<br />
    ZG/iOH/V0O/n/m4rWQvJhkc90Xp8XRObcua+7ljkYmQt2+VljQJOEMOKBJnkPX7d<br />
    uJsjGTA79A/kjDsP/0G1hEfMlHJXuqcEm3qJLat807SFFw8Ce/nljN811+JUvlAM<br />
    0l09rZFz0zsvwKf7LrxgB/5cg2L7rPtvL77pRhxEpmHErOTqdEjQwJOhN0jO7Yyy<br />
    OgsyIkHCgNVyeaY/7cqOgLxPr4svbww55aAAV4fupqXZnbeGVLAUxujXjuk+4qCw<br />
    u7yKM0U0cfEMbXsvElN2bRdUuUXdjnMhiJE+9gZt8B2tUjlLbqYOctIsvoMnln1Z<br />
    7Bq3+I8jRh4VhqCd2dYVf77rz7fCZpZlmTy7ZqXGCOcSGiDEOdsRtGrG0mG1kGvf<br />
    fbxTp9KLa32D+QUfZ5KkTEWNr6XfzlZl6rkuuFHD/1T5LDLw9nViBlEMROcDXCpV<br />
    B8MGumKRhJWngoG5Tl+iQsAZpCnY8wiTFVTbm5aqDSt8TtB10VYQ+44vMc9eUAtq<br />
    lUVYS4vmhJnQ0sVoykBOYxULJNceGxWmZFdkPnQ0WjuE0xLCznMQrSYSLFlVp40b<br />
    Eu5+DLG2W31rJmAmHdBffDbaptrlwmLqrNvSjLr59fFxcfJerO1OcFPVfYdV1jCE<br />
    x4wKECQGAYsC4snM92PfY25Edh/fhgTp55a//IfE4VAAyn7cy2Hrk1ybO0EWrC5c<br />
    dl4EJfx6S0EUZSbuIVRzH0KEkkcPSO5ZyJcCa4PWb4oqwMVyhMCByky2sfviuQIN<br />
    BF+7AQQBEACVXUmC1nwPm7a8MRdNq5pJ2ilq2Ru6km1fZXMWSBmppQ04IE1FPuXS<br />
    SLD64kHiSZIJrD/W2n4XcGWZ3lngxeqC0u55p+G0EBgApRMYAstzYfxY8XXa6bD4<br />
    ReqqqhAPb0XB12JDjEMiiQsvFy8h6P05Rqlys/AawCHu3lNKRawPXAWXsbyEC+zE<br />
    XjYkLt5ptaXr12Xrd3H5x7BAwrZFztzIC4ahS0dwUfKsrPPq2jkPv98PzsU/lI/h<br />
    L9z501vQPYlAsPUWw2v1Nlc/+7t78AAFRDz4ZyGhs9/2bzkKsGWs/jg4RZIBenXt<br />
    2V8EdPbI7oWSBfMEf5A2PPfbZPEklX4ymJzDQdelJyBL3Yk/XdtoSU4HHTaVDN8c<br />
    O+cUTiJroiA7uyMVdaybnwrUi2E2/5LqPMrXJFUzpQbrfQrC0x/qzdXu9nqvJA42<br />
    tpghmWV3PO9i+pnwe20PTSwEKGjyvJQAdoHphf0G9ZNWyxX0bDwmjSB0KZea/VCZ<br />
    j+ZIeL8LLa9POyt1yBCKJ8379oBBW1Fk3P7jKClTVVK09hF5MOwmAqaLHc09aOrb<br />
    H9YxqmRc1LALOhP8RGWR5i7dsDxoIo7r4FO9AxtvFgqSKDiwf4HxsPRKmVCMAtKH<br />
    jB5qp+PR+NjdMQG4qaWM9MSDwbU7HyQ+uUhejrVMjjyEYcm4JPPhSwARAQABiQI8<br />
    BBgBCgAmFiEEEbHXzHYE1VrifhyC+9I9IHn3rmoFAl+7AQQCGwwFCQHhM4AACgkQ<br />
    +9I9IHn3rmqfsRAAjlO7uU2cUDczBYHLHal1pzCbgv4OaQ4/w749gLfVv1icSxoc<br />
    8K4VRTQTDLSouKECZRn+ey6igwYGsVz5r4j1T/MMM4KD5qF/+ikSUUnXuWEUpT9d<br />
    qBqBWr+NaLo8lTC2S63qsAjVJIyLo1L0NA+bO4PXgkIiY4OFkJgWBmoZOgitu5Vj<br />
    YZ+jn8rCrcueLD3Qf7ZvuWxeKrG0MEhtV1POqPPc16rDABRPmhVGf4n1A9Zs61xd<br />
    tfIOkKS4gLFR8pvLUxiIDdzvKhySqjtNE7zUkKyR/+LTEGz3ozRGBoZ3K0/akUle<br />
    OLjPcFfu/ecnhjSzMDNTanI7bOqWu5pKti6HS6iHWrjqHJiiu/muFk70SDIsy9e9<br />
    Y+b7e4hIiwP1b3Jum8q5TIEkDVNjMIoBWxLv4+9GM1LQctoNAiFl7U4pCEAxm0PZ<br />
    M53h/nkEbwtCRj4S8lb0FPEQJYRl5D/AVhAkAIToLcmvaEmtqvqIUNJrEOb0i8Zq<br />
    ndKAoiRahM9sdmloWMVJkUMBdmyOJ7ENVX7PMirtYzUxMleNk6S2mWV2WawAfjOd<br />
    BMsJUzk5vMHYK8mmQJELoiA9iuuns0ks0W7RC8eOXmt4xtxbHAY+BQlQjjOqTKXU<br />
    zGl6sHWjEmb50a6th0GtzOn4+tS/rJ3d45UPWz2+SGb9p64qp3x6kKtY0j65Ag0E<br />
    X7sBMQEQANznr4MACjIYHVFJyyFEdkTOESuI0uCRW2kA2umJZnFNeWPfe8r/6D/1<br />
    jJE+bi82nXnprhNpYA8/+6FXWJdgkagf6yLRkgVdPZkzqjOfK6DkEIeO4YTzfc4s<br />
    r8jFd7zmBub5GzCX8EpcJe+LoBrBOLcgHnjzfcLIFa6ElwnP4sojyD4BNi9F5Gi6<br />
    99U/QLkznw5qT02ON6bUKdIF5cyq9vlx2W1zJcquyjf5DKBmof8Em8V92RfU544C<br />
    O3/0mGofF5Sslz3U7tohYBhdBSOlYTFLaU7iU99ewV5vKXxHxQY9E1k3oUjcviuz<br />
    Oo0WI0yXA1yqIxpdhqNkCDUpUtRhH6vI9y5E93PbD+3KEjeFmcN8cTSb5es9tmY7<br />
    pnhBew1Ww1BXi218EhHlCWTaqCwBNC+/ofWR2KaF5eaQRCRGiqCZ9rRY5m667/LQ<br />
    Eb1u4Bf+cnDbsTE9OSvymAEoBeeEI0UifqfwBtflXcWgEeJHTn1pG+xIxErsgCWz<br />
    rOvirT8F7ALhTfIlwOl5R/SXhtvmNQH6y7QiArIURd69A9tF5kvBRtMf95vxT4lc<br />
    PWlHjf8E+rK4v7tkbJpG82nedg3FF/EkdXRjGVEY2Ag3RBhI+mCFy1LeziGjqxSr<br />
    vwAjfDgYmlpvjmb6LgqY6YYk7r5Wah1yquK/avbLTkHNgwwmncozABEBAAGJAjwE<br />
    GAEKACYWIQQRsdfMdgTVWuJ+HIL70j0gefeuagUCX7sBMQIbIAUJAeEzgAAKCRD7<br />
    0j0gefeuanuwEACsfvG3Pw9oEYwag3IMH1sPhqdqqO6tNc9pZeJX7z6T6AY5TxJW<br />
    IO55dFLOrKh8scRbE5VWwhycDds6rCBH1i9YxJ1xfI3Rxnkdrtwg/R8lES9/T+mD<br />
    SG0pd138sl7NAAb98IJ/oqQMeshlSBwOUMi0B4/VHyCr0B1llHJolf84ndrysd+C<br />
    x4nfdR8MBgW1tsgy5BD9mwMRoGeW95nYAUHlbXt0M9A7pslJIaaNEgE53wDcE+7e<br />
    ofA/i1JYUk6R6Zssd5hXo18vOQgzj6p37sth47UJ9F7AtTKpj6YloK3kmthlRyE6<br />
    +fYE7m9WbzVwffcgPDWwyCxXY/UkQWfe0mpMUYcBtH6AoGhhcakYOMQ/DU8dES6k<br />
    1NxZVVNo3Kv3TtRXPShhv/4dBJdtGBkqiBpqNMbIC5sRbRBSaxuuLJh8Copz78lt<br />
    AeDWv5p8y3JAhYM+kDOooFbVczrMWr3a+/XqYfLbbObUDMfoQFIqS+7yUk+1WuCV<br />
    AFe1T+/Md43q0scStgh0FHOtKF9fHgrZDzYQZYPhz3Gcpdm/Q0Mw1RLo34VbaPvK<br />
    Qo25+GBKhmof77zGSdip2fSkJbZ2W/s4cQP4UNQU7KLBm/7C0thtJQ3Y0r2zGO1E<br />
    EHY2ZBLOg/ZrFE7LQgAlVV8/boKE0ImGYeCPI55t4BvOp5cT2ysQGA64FA==<br />
    =l5Ku<br />
    -----END PGP PUBLIC KEY BLOCK-----
    </text>)
  }